<template>
  <div v-if="shops">
    <CRow>
      <CCol md="12">
        <CCard color="white" text-color="dark" class="text-center col-md-12 shadow-sm">
          <CCardBody class="mt-4">
            <CRow>
              <CCol md="2"></CCol>
              <CCol md="8">
                <h2 class="font-weight-normal text-dark">
                  {{ $t('transection') }}
                </h2>
              </CCol>
              <CCol md="2" class="text-right">
                <a @click="$router.go(-1)">
                  <CButton>
                    <CIcon name="cil-arrow-thick-from-right" />
                    {{ $t('back') }}
                  </CButton>
                </a>
              </CCol>
            </CRow>

            <hr />
            <CJumbotron class="row p-1" color="light">
              <div class="col-md-2 col-sm-12 mt-3 mb-2">
                {{ $t('selectDate') }}
              </div>
              <div class="col-md-8 col-sm-12 mb-2 mt-2">
                <v-md-date-range-picker opens="left" :presets="updatePresetsRangePicker()" :currentLang="getCurrentLang()"
                  :locale="getCurrentLang()" @change="handleChangeDate">
                  <template slot="input" class="activator-wrapper">
                    <input type="text" readonly="readonly" style="background-color: white;"
                      class="form-control input-group-text" :value="dateText" />
                  </template>
                </v-md-date-range-picker>
              </div>
              <div class="col-md-2 col-sm-12 mb-2 mt-2">
                <CButton color="primary" block @click="getTransactionExcel" v-if="loadingButton === false">
                  <i class="far fa-file-excel"></i> {{ $t('export') }}
                </CButton>
                <CButton color="primary" block v-else-if="loadingButton === true" disabled>
                  <CSpinner color="white" size="sm" />
                  <i class="far fa-file-excel"></i> {{ $t('export') }}
                </CButton>
              </div>

            </CJumbotron>

            <CDataTable :items="items" :fields="fields" hover border style="white-space: nowrap;">
              <template #receiptStatus="{ item }">
                <td v-if="item.transactionStatus === 'PP' || item.transactionStatus === 'I'">
                  <span >-</span>
                </td>
                <td v-else>
                  <CBadge :color="getBadge(item.receiptStatus)">
                    <span class="font-weight-normal">&nbsp;{{  getReceiptStatus(item.receiptStatus) }}&nbsp;</span>
                  </CBadge>
                </td>
              </template>
            </CDataTable>
            <pagination :meta_data="meta_data" v-on:next="getTransaction">
            </pagination>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'
import moment from 'moment'
import util from '@/util/util'
import Pagination from '@/containers/Pagination'
import NoShop from '@/containers/NoShop'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import i18n from '@/plugins/i18n'

export default {
  components: {
    DatePicker,
    Pagination,
    NoShop,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    let self = this
    return {
      min: minDate,
      dateText: moment().format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
      data: {},
      detail: [],
      member: '',
      memberObjectId: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      attr: '',
      keyword: '',
      showrewardcard: '',
      date: [new Date(), new Date()],
      startAt: '',
      endAt: '',
      loadingButton: false,
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    fields() {
      if (this.showrewardcard === true) {
        return [
          {
            key: 'index',
            label: '#',
            _classes: 'text-center text-dark font-weight-normal',
          },
          {
            key: 'date',
            label: i18n.t('date'),
            _classes: 'text-left text-dark font-weight-normal',
          },
          { key: 'member', label: i18n.t('member.txtmember'), _classes: 'text-left text-dark font-weight-normal' },
          {
            key: 'receiptNumber',
            label: i18n.t('receiptNumber'),
            _classes: ' text-left font-weight-normal',
          },
          {
            key: 'receiptStatus',
            label: i18n.t('receiptStatus'),
            _classes: 'text-center text-dark font-weight-normal',
          },
          { key: 'grandTotal', label: i18n.t('amount'), _classes: 'text-right text-dark font-weight-normal' },
          { key: 'point', label: i18n.t('pointRecive'), _classes: 'text-right text-dark font-weight-normal' },
          {
            key: 'rewardpoint',
            label: i18n.t('loyaltyCard'),
            _classes: 'text-right text-dark font-weight-normal',
          },
          { key: 'isStatus', label: i18n.t('transactionStatus'), _classes: 'text-left text-dark font-weight-normal' },
        ]
      } else if (this.showrewardcard === false) {
        return [
          {
            key: 'index',
            label: '#',
            _classes: 'text-center text-dark font-weight-normal',
          },
          {
            key: 'date',
            label: i18n.t('date'),
            _classes: 'text-left text-dark font-weight-normal',
          },
          { key: 'member', label:  i18n.t('member.txtmember'), _classes: 'text-left text-dark font-weight-normal' },
          {
            key: 'receiptNumber',
            label:  i18n.t('receiptNumber'),
            _classes: 'text-left text-dark font-weight-normal',
          },
          {
            key: 'receiptStatus',
            label: i18n.t('receiptStatus'),
            _classes: 'text-center text-dark font-weight-normal',
          },
          { key: 'grandTotal', label: i18n.t('amount'), _classes: 'text-right text-dark  font-weight-normal' },
          { key: 'point', label:  i18n.t('pointRecive'), _classes: 'text-right text-dark font-weight-normal' },
          { key: 'isStatus', label:  i18n.t('transactionStatus'), _classes: 'text-left text-dark font-weight-normal' },
        ]
      }
    },
    items() {
      const transaction = this.detail || []
      let info = []
      for (let i = 0; i < transaction.length; i++) {
        const data = transaction[i]
        const member = data.member || {}
        const fullName = (member.firstname || member.name || '') + ' ' + (member.lastname || '')
        let receiptNumber = data.receiptNumber || '-'
        let receiptStatus = ''
        let point = data.point || '0'
        let isStatus = '-'
        let classes = 'text-black'
        let rewardpoint = data.rewardPoint || '-'
        let start = this.meta_data.current_page * 50 - 50
        let createdAt = moment(String(data.created_at)).format('DD/MM/YYYY HH:mm:ss') || '-'

        if (data.receiptStatus === 'A') {
          receiptStatus = 'สำเร็จ'
          isStatus = this.$i18n.t('saleAmount')
        } else if (data.receiptStatus === 'V') {
          receiptStatus = 'ยกเลิกการขาย'
          isStatus = this.$i18n.t('saleAmount')
          classes = 'text-danger'
        } else if (data.receiptStatus === 'I') {
          isStatus =  this.$i18n.t('sendLoyaltyPoints')
        } else if (data.receiptStatus === 'PP') {
          isStatus = this.$i18n.t('retrieveLoyaltyPoints')
        }

        info.push({
          index: start + i + 1,
          date: createdAt,
          member: fullName,
          receiptNumber: receiptNumber,
          receiptStatus: receiptStatus,
          grandTotal: util.convertCurrency(data.grandTotal),
          point: util.convertNumber(point),
          rewardpoint: rewardpoint,
          isStatus: isStatus,
          transactionStatus: data.receiptStatus,
          _classes: classes,
        })
      }
      return info
    },
  },
  mounted() {
    this.getTransaction()
    this.getShowCheckRewardCardTrans()
  },
  methods: {
    updatePresetsRangePicker() {
      return [{
        label: i18n.t('today'),
        range: this.getRange(0, 0)
      }, {
        label: i18n.t('yesterday'),
        range: this.getRange(1, 1)
      }, {
        label: i18n.t('last7days'),
        range: this.getRange(6, 0)
      }, {
        label: i18n.t('last30days'),
        range: this.getRange(29, 0)
      }, {
        label: i18n.t('thismonth'),
        range: this.getRange(0, 0, 'month')
      }, {
        label: i18n.t('lastmonth'),
        range: this.getRange(1, 1, 'month')
      }]
    },
    setDate(start, end) {
      // let start = moment(this.date.start).format('DD/MM/YYYY')
      // let end = moment(this.date.end).format('DD/MM/YYYY')
      return start + ' - ' + end
    },
    getCurrentLang() {
      return this.$i18n.locale
    },
    handleChangeDate(values) {
      this.values = values
      const date = {
        start: moment(values[0])
          .locale('en')
          .format('YYYY-MM-DD'),
        end: moment(values[1])
          .locale('en')
          .format('YYYY-MM-DD'),
      }
      this.startAt = date.start
      this.endAt = date.end
      this.dateText = this.setDate(moment(values[0])
        .locale('en')
        .format('DD/MM/YYYY'),
        moment(values[1])
          .locale('en')
          .format('DD/MM/YYYY'))

      this.keyword = date

      if (this.startAt === 'Invalid date') {
        this.keyword = ''
      }
      this.getTransaction()


    },
    handleChange(values) {
      const date = {
        start: moment(values[0]).format('YYYY-MM-DD'),
        end: moment(values[1]).format('YYYY-MM-DD'),
      }

      this.startAt = date.start
      this.endAt = date.end

      this.keyword = date

      if (this.startAt === 'Invalid date') {
        this.keyword = ''
      }

      this.getTransaction()
    },
    getRange(startOffset = 0, endOffset = 0, period = 'day') {
      return [moment().subtract(startOffset, period).startOf(period), moment().subtract(endOffset, period).endOf(period)];
    },
    getReceiptStatus(status){
      switch (status) {
        case 'สำเร็จ':
          return this.$i18n.t('completely')
        case 'ยกเลิกการขาย':
          return this.$i18n.t('voidbill')
        default:
          return 'primary'
      }
    },
    getBadge(receiptStatus) {
      switch (receiptStatus) {
        case 'สำเร็จ':
          return 'success'
        case 'ยกเลิกการขาย':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getTransaction(page = 1) {
      const uid = this.uid

      if (this.keyword === '') {
        this.attr = ''
      } else {
        this.attr = 'date'
      }

      let params = {
        page: page,
        limit: 50,
        attr: this.attr,
        keyword: this.keyword,
        start: this.startAt,
        end: this.endAt,
      }

      crm
        .get('/api/v1.0/' + uid + '/gettransaction', { params: params })
        .then((res) => {
          if (res.data.data !== undefined && res.data.data.length != 0) {
            this.detail = res.data.data
            this.meta_data.last_page = res.data.paginate.pageCount
            this.meta_data.current_page = res.data.paginate.currentPage
          } else {
            this.detail = []
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    getTransactionExcel() {
      this.loadingButton = true
      const uid = this.uid

      let params = {
        start: this.startAt,
        end: this.endAt,
      }

      crm({
        url: '/api/v1.0/' + uid + '/gettransaction/excel',
        params: params,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'ประวัติการทำรายการ.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
          this.loadingButton = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getShowCheckRewardCardTrans() {
      const uid = this.uid
      crm
        .get('/api/v1.0/' + uid + '/shop/getmenubar')
        .then((response) => {
          let documents = response.data.data
          this.showrewardcard = documents.rewardcard
        })
        .catch((e) => {
          console.error(e)
        })
    },
  },
}
</script>
<style>
.mdrp-root {
  width: 100%;
}
</style>
